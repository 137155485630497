import React, { useState } from "react";
import "../style/Section.css";
import "../style/Navbar.css";
import logo from "../resources/ff-logo.svg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Dropdown from "./Dropdown";

const Navbar = ({ children }) => {
  const [isHidden, setHidden] = useState(true);
  const [smallShopMenuVisible, setsmallShopMenu] = useState(false);
  const [smallPressMenuVisible, setsmallPressMenu] = useState(false);
  const [burgerStatus, setBurgerStatus] = useState(false);

  const handleClick = () => {
    setHidden(!isHidden);
    setBurgerStatus(!burgerStatus);
  };

  const handleExpand = () => {
    setsmallShopMenu(!smallShopMenuVisible);
  };

  const handlePressExpand = () => {
    setsmallPressMenu(!smallPressMenuVisible);
  };

  const hiddenMenuShopLinks = (
    <section class={`small-expand-${smallShopMenuVisible}`}>
      {/* {smallShopMenuVisible ? ( */}
      <div>
        <div>
          <a href="https://shop.freshface.io">FreshFace.</a>
        </div>
        <div>
          <a href="https://glymedplus.com/store/freshface">GlyMedPlus</a>
        </div>
      </div>
      {/* ) : (
        <a></a>
      )} */}
    </section>
  );

  const hiddenMenuPressLinks = (
    <section class={`small-expand-${smallPressMenuVisible}`}>
      {/* {smallPressMenuVisible ? ( */}
      <div>
        <div>
          <a href="https://shoutoutatlanta.com/meet-laquam-easter-black-esthetician/">
            Shoutout Atlanta
          </a>
        </div>
      </div>

      {/* : (
        <a></a>
      )} */}
    </section>
  );

  return (
    <nav>
      <div className="navbar">
        <span>
          <Dropdown
            title="press"
            links={{
              "Shoutout Atlanta":
                "https://shoutoutatlanta.com/meet-laquam-easter-black-esthetician/",
            }}
          />
        </span>

        <span>
          <HashLink
            to={{
              pathname: "/",
              hash: "about-section",
            }}
          >
            About
          </HashLink>
        </span>
        <span>
          <a href="https://www.vagaro.com/ffct/">Services</a>
        </span>
        <span>
          <HashLink
            to={{
              pathname: "/",
              hash: "locations-section",
            }}
          >
            Location
          </HashLink>
        </span>
        <Link to="/" id="link-logo">
          <img src={logo} id="nav-logo" />
        </Link>
        <span>
          <HashLink
            to={{
              pathname: "/",
              hash: "appointment-section",
            }}
          >
            Booking
          </HashLink>
        </span>
        <span>
          {/* <a href="http://shop.freshface.io">Shop</a> */}
          <Dropdown />
        </span>
        <span>
          <a href="mailto:customerservice@freshface.io">Contact</a>
        </span>

        <span>
          <HashLink
            to={{
              pathname: "/quiz",
            }}
          >
            Quiz
          </HashLink>
        </span>
      </div>

      <section class="ham-logo">
        <span className="hamburger" onClick={handleClick}>
          <div></div>
          <div></div>
          <div></div>
        </span>

        <span className="mobile-logo">
          <Link to="/" id="link-logo">
            <img src={logo} id="nav-logo" />
          </Link>
        </span>
      </section>

      {/* hidden nav */}

      <div className={isHidden + "-hidden" + " sidebar"}>
        <HashLink
          to={{
            pathname: "/",
            hash: "about-section",
          }}
        >
          <div>About</div>
        </HashLink>
        <a href="https://www.vagaro.com/ffct/services">
          <div>Services</div>
        </a>
        <HashLink
          to={{
            pathname: "/",
            hash: "locations-section",
          }}
        >
          <div>Location</div>
        </HashLink>
        {/* <Link to="/" id="link-logo">
          <img src={logo} id="nav-logo" />
        </Link> */}
        <HashLink
          to={{
            pathname: "/",
            hash: "appointment-section",
          }}
        >
          <div>Booking</div>
        </HashLink>
        <a onClick={handleExpand}>
          <div>
            Shop
            {hiddenMenuShopLinks}
          </div>
        </a>
        <a href="mailto:customerservice@freshface.io">
          <div>Contact</div>
        </a>
        <a onClick={handlePressExpand}>
          <div>
            Press
            {hiddenMenuPressLinks}
          </div>
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
