import React from "react";
import "../style/Section.css";
import "../style/Footer.css";

const Footer = ({ children, id }) => {
  return (
    <div id={id} className="footer">
      {children}
    </div>
  );
};

export default Footer;
