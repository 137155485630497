export {};

export const style = {
  page: {
    width: "150%",
    height: "55rem",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridColumn: "2/4",
    margin: "auto",
  },
  card: {
    paddingLeft: "1.2rem",
    paddingRight: "1.2rem",
    maxWidth: "30rem",
    paddingTop: "5rem",
    margin: "auto",
    padding: "auto",
    textAlign: "center" as "center",
  },
  button: {
    display: "block",
    margin: "auto",
    marginBottom: "1rem",
    minWidth: "100%",
    minHeight: "2rem",
    // fontWeight: "bold",
    fontSize: "1.2rem",
  },
  text: {
    fontSize: "clamp(1.5rem, 3vw, 2rem)",
    // fontWeight: "bold",
    marginBottom: "3rem",
  },
  list: {
    // float: "left",
    // textAlign: "left",
    margin: "1em",
    fontSize: "clamp(1.5rem, 4vw, 2rem)",
    fontFamily: "var(--headline-font)",
    fontWeight: "bold" as "bold",
  },
};
