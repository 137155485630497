import './App.css';
import Grid from './components/Grid';
import Section from './components/Section';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Hero from './components/static/Hero';
import About from './components/static/About';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import logo from "./resources/ff-full-logo.svg";
import calendar from "./resources/calendar.svg"
import { useEffect, useState } from 'react';
import './style/quotes.css';
import { quotes } from "./resources/quotes";
import { BrowserRouter as Router, Switch, Link, Route } from "react-router-dom";
import Home from "./pages/Home";
import Quiz from './pages/Quiz';
import { HashLink } from 'react-router-hash-link';
import Dropdown from './components/Dropdown';


function App() {

  const [quote, setQuote] = useState(quotes[1]);

  useEffect(() => {
    setTimeout(() => {
      setQuote(quotes[Math.floor(Math.random() * quotes.length)])
    }, 2000)
  })

  return (
    <div>
      <Grid>
        <Router>
          {/* ----- Navbar ----- */}
          <Navbar />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/quiz" >
              <Quiz />
            </Route>
          </Switch>
          <br></br>
          {/* ----- Footer ----- */}
          <Footer id="footer-section">
            <div className="footer-block">
              <img src={logo} width="300px" />
            </div>
            <div className="footer-block">
              <ul className="footer-links">
                <li>
                  <HashLink
                    to={{
                      pathname: "/",
                      hash: "about-section",
                    }}
                  >
                    about
                  </HashLink></li>
                <li>
                  <a href="https://www.styleseat.com/m/v/freshfacect2">services</a>
                </li>
                {/* <li>faq</li> */}
                <li><a href="https://shop.freshface.io">shop</a>
                </li>
                <li><a href="mailto:customerservice@freshface.io">contact</a></li>
              </ul>
            </div>
            <div className="footer-block">
              FreshFace.
              <br></br>
              <br></br>
              <br></br>
              210 S. Main St, Suite 300B,
              <br></br>
              Middletown, CT 06457
              <br></br>
              <br></br>
              <b>customerservice@freshface.io</b>
              <br />
              <b>(860) 899-9073</b>
              <br></br>
              <br></br>
              <small>Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">
                Smashicons </a>
                and
                <a href="https://www.flaticon.com/authors/icongeek26" title="Icongeek26"> Icongeek26</a>
                <br></br>
                from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
                <br></br>
                Site designed by <a href="https://lionel.works">lionel.works</a>
              </small>
            </div>
          </Footer>
        </Router>
      </Grid>
    </div >
  );
}

export default App;
