import react, { useState } from "react";
import { style } from "../style/QuizStyle";
import { ffqna } from "../pages/freshfaceqna";

// const _questionAndAnswers: QnA[] = [
//   {
//     question: "do you know the muffin man?",
//     answers: ["yes", "no"],
//     onAnswer: (answer: String) => {
//       switch (answer) {
//         case "yes":
//           setQuestion(2);
//           break;
//         case "no":
//           setQuestion(1);
//           break;
//       }
//     },
//   },
//   {
//     question: "do you want to know the muffin man?",
//     answers: ["yes", "no"],
//     onAnswer: (answer: string) => {
//       setQuestion(0);
//     },
//   },
//   {
//     question: "Oh awesome!",
//     answers: ["okay...", "i guess"],
//     onAnswer: (answer: string) => {
//       setQuestion(0);
//     },
//   },
// ];

interface QnA {
  question: String;
  answers?: String[];
  onAnswer: Function;
  services?: [string[], string[]];
}

const Quizlet = () => {
  const [answersState, setAnswers] = useState<String[][]>([]);
  const [currentQuestion, setQuestion] = useState(0);
  const [hasDrySkin, setDrySkin] = useState(false);

  // questions and answers array
  const questionAndAnswers: QnA[] = [
    // 0
    {
      question: "Is this your first professional skin treatment?",
      answers: ["yes", "no"],
      onAnswer: (answer: String) => {
        switch (answer) {
          case "yes":
            setQuestion(1);
            break;
          case "no":
            setQuestion(2);
            break;
        }
      },
    },
    // 1
    {
      question: "How does your skin feel on a regular basis?",
      answers: ["dry", "oily", "a little bit of both"],
      onAnswer: (answer: string) => {
        switch (answer) {
          case "dry":
            setDrySkin(true);
            setQuestion(4);
            break;
          case "oily":
            setQuestion(3);
            break;
          case "a little bit of both":
            setQuestion(3);
            break;
        }
      },
    },
    // 2
    {
      question: "What is your biggest skin concern right now?",
      answers: ["dryness", "oilyness", "breakouts", "redness"],
      onAnswer: (answer: string) => {
        switch (answer) {
          case "dryness":
            setQuestion(4);
            break;
          case "oilyness":
            setQuestion(5);
            break;
          case "breakouts":
            setQuestion(5);
            break;
          case "redness":
            setQuestion(4);
            break;
        }
      },
    },
    // 3
    {
      question: "Do you also experience breakouts?",
      answers: ["yes", "no"],
      onAnswer: (answer: string) => {
        // answer doesn't affect flowchart
        setQuestion(6);
      },
    },
    // 4
    {
      question: "Do you experience dry patches or overall dryness?",
      answers: ["patches", "overall dryness"],
      onAnswer: (answer: string) => {
        // answer doesn't affect flowchart
        setQuestion(6);
      },
    },
    // 5
    {
      question: "Do you experience oilyness...",
      answers: ["gradually more throughout the day", "oily all day, everyday"],
      onAnswer: (answer: string) => {
        // answer doesn't affect flowchart
        setQuestion(6);
      },
    },
    // 6
    {
      question: "does your skin ever experience sensitivities?",
      answers: ["yes, to certain products", "yes, to the sun", "no"],
      onAnswer: (answer: string) => {
        switch (answer) {
          case "yes, to certain products":
            hasDrySkin ? setQuestion(10) : setQuestion(7);
            break;
          case "yes, to the sun":
            hasDrySkin ? setQuestion(10) : setQuestion(9);
            break;
          case "no":
            hasDrySkin ? setQuestion(10) : setQuestion(8);
            break;
        }
      },
    },
    // 7
    {
      question: "We recommend the following services...",
      services: [
        ["Signature Facial", "https://www.vagaro.com/ffct/services"],
        ["Oxygen Facial", "https://www.vagaro.com/ffct/services"],
      ],
      onAnswer: (answer: string) => {
        setQuestion(0);
      },
    },
    // 8
    {
      question: "We recommend the following services...",
      services: [
        ["Signature Facial", "https://www.vagaro.com/ffct/services"],
        ["The Luxe Glow", "https://www.vagaro.com/ffct/services"],
      ],
      onAnswer: (answer: string) => {
        setQuestion(0);
      },
    },
    // 9
    {
      question: "We recommend the following services...",
      services: [
        [
          "Signature Facial (with LED Light Therapy)",
          "https://www.vagaro.com/ffct/services",
        ],
        ["CBD Facial", "https://www.vagaro.com/ffct/services"],
      ],
      onAnswer: (answer: string) => {
        setQuestion(0);
      },
    },
    // 10
    {
      question: "We recommend the following services...",
      services: [["HydroFacial", "https://www.vagaro.com/ffct/services"], []],
      onAnswer: (answer: string) => {
        setQuestion(0);
      },
    },
  ];

  // engine

  const handleClick = (selectedAnswer: String) => {
    console.log(selectedAnswer);
    const answersTemp: String[][] = answersState;
    answersTemp.push([
      questionAndAnswers[currentQuestion].question,
      selectedAnswer,
    ]);
    console.log(answersState);
    setAnswers(answersTemp);
  };

  // rendered quiz

  return (
    <div style={style.card}>
      <p style={style.text}>
        {questionAndAnswers[currentQuestion].question}
        <hr></hr>
      </p>
      {questionAndAnswers[currentQuestion].answers?.map((answer) => (
        <button
          style={style.button}
          onClick={() => {
            handleClick(answer);
            questionAndAnswers[currentQuestion].onAnswer(answer);
          }}
        >
          {answer}
        </button>
      ))}
      {currentQuestion > 6 ? (
        <section>
          {questionAndAnswers[currentQuestion].services?.map((service) => (
            <p style={style.list}>
              <a href={service[1]}>{service[0]}</a>
            </p>
          ))}
          <br></br>
          <button style={style.button} onClick={() => setQuestion(0)}>
            restart quiz
          </button>
          <a href="/">
            <button style={style.button}>back to home page</button>
          </a>
          <details
            style={{
              border: "0.1rem black solid",
              padding: "1rem",
              margin: "1rem",
            }}
          >
            <summary>
              <b>Show your Answers</b>
            </summary>
            {answersState.map((pairing) => (
              <section style={{ textAlign: "left" }}>
                <p style={{ fontStyle: "italic" }}>Q: {pairing[0]}</p>
                <p> A: {pairing[1]}</p>
              </section>
            ))}
          </details>
        </section>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Quizlet;
