import React, { useState } from "react";
import Quizlet from "../components/Quizlet";

const Quiz = () => {
  const questionAndAnswers = {
    1: {
      1: {
        question: "Is this your first professional skin treatment?",
        answers: ["yes", "no"],
      },
    },
    2: {
      1: {
        question: "How does your skin feel on a regular basis?",
        answers: ["dry", "oily", "a little bit of both"],
      },
      2: {
        question: "What is your biggest skin concern right now?",
        answers: ["dryness", "oilyness", "breakouts", "redness"],
      },
    },
    3: {
      1: {
        question: "Do you also experience breakouts?",
        answers: ["yes", "no"],
      },
      2: {
        question: "Do you experience dry patches or overall dryness?",
        answers: ["patches", "overall dryness"],
      },
      3: {
        question: "Do you experience oilyness...",
        answers: [
          "gradually more throughout the day",
          "oily all day, everyday",
        ],
      },
    },
    4: {
      1: {
        question: "does your skin ever experience sensitivities?",
        answers: ["yes, to certain products", "yes, to the sun", "no"],
      },
    },
    5: {
      1: {
        question: "dry: We recommend the following services...",
        answers: [],
        services: [
          ["Signature Facial", "https://www.vagaro.com/ffct/services"],
          ["Oxygen Facial", "https://www.vagaro.com/ffct/services"],
        ],
      },
      2: {
        question: "We recommend the following services...",
        answers: [],
        services: [
          ["Signature Facial", "https://www.vagaro.com/ffct/services"],
          ["The Luxe Glow", "https://www.vagaro.com/ffct/services"],
        ],
      },
      3: {
        question: "We recommend the following services...",
        answers: [],
        services: [
          [
            "Signature Facial (with LED Light Therapy)",
            "https://www.vagaro.com/ffct/services",
          ],
          ["CBD Facial", "https://www.vagaro.com/ffct/services"],
        ],
      },
    },
  };

  // represents the step of the quiz currently displayed
  // the current quiz has 5 steps dipsplaying different questions and answers
  const [currentIndex, setCurrentIndex] = useState(1);
  // represents the possible permutation of any given page
  // the question and answers objects is composed of nested objects
  // resulting in the need for a subindex
  const [currentSubIndex, setSubIndex] = useState(1);
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  const handleClick = (answerIndex, e) => {
    console.log(
      questionAndAnswers[currentIndex][currentSubIndex].question,
      questionAndAnswers[currentIndex][currentSubIndex].answers[answerIndex]
    );

    const AnswerArray = [
      questionAndAnswers[currentIndex][currentSubIndex].question,
      questionAndAnswers[currentIndex][currentSubIndex].answers[answerIndex],
    ];

    const answerState = selectedAnswers;
    answerState.push(AnswerArray);
    console.log(answerState);
    setSelectedAnswers(answerState);

    // answerIndex is off by 1 when compared to the subIndex
    // so adding one is necessary if we intend to compare the two meaningfully
    const subIndex = answerIndex + 1;

    // engine of the quiz resolving in a complex web of imperative logic
    // may need to be refactored to utilize a switch statement in a more
    // idiomatic, ledgible fashion

    // switch (currentIndex) {
    //   case 2:
    //     if (currentSubIndex === 2) {
    //       if (subIndex === 1 || subIndex === 4) {
    //         setSubIndex(2);
    //       } else {
    //         setSubIndex(3);
    //       }
    //     }
    //   case 3:
    // }

    if (currentIndex === 2) {
      console.log("the current index is 2!");
      if (currentSubIndex === 2) {
        console.log("the current subindex is 2!");
        if (subIndex === 1 || subIndex === 4) {
          console.log("1 or 4!");
          setSubIndex(2);
        } else {
          setSubIndex(3);
        }
      } else if (currentSubIndex === 1) {
        if (subIndex === 1) {
          setSubIndex(2);
        } else {
          setSubIndex(1);
        }
      }
    } else if (currentIndex === 3) {
      setSubIndex(1);
    } else {
      setSubIndex(subIndex);
    }

    if (currentIndex !== 5) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const style = {
    page: {
      // width: "150%",
      minHeight: "40rem",
      gridTemplateColumns: "repeat(3, 1fr)",
      gridColumn: "1/6",
      margin: "auto",
    },
    card: {
      paddingLeft: "1.2rem",
      paddingRight: "1.2rem",
      maxWidth: "30rem",
      paddingTop: "5rem",
      margin: "auto",
      padding: "auto",
      textAlign: "center",
    },
    button: {
      display: "block",
      margin: "auto",
      marginBottom: "1rem",
      minWidth: "100%",
      minHeight: "2rem",
      // fontWeight: "bold",
      fontSize: "1.2rem",
    },
    text: {
      fontSize: "clamp(1.5rem, 3vw, 2rem)",
      // fontWeight: "bold",
      marginBottom: "3rem",
    },
    list: {
      // float: "left",
      // textAlign: "left",
      margin: "1em",
      fontSize: "clamp(1.5rem, 4vw, 2rem)",
      fontFamily: "var(--headline-font)",
      fontWeight: "bold",
    },
  };

  return (
    <main style={style.page}>
      <Quizlet />
    </main>
  );
};

export default Quiz;
