import React from "react";
import "../style/Grid.css";

const Grid = ({ children }) => {
  return (
    <div className="main-grid">
      {children}
    </div>
  );
};

export default Grid;
