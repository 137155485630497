import React from "react";
import Section from "../Section";
import { Link } from "react-router-dom";

const Hero = ({ children, id }) => {
  return (
    <Section id={id}>
      <div className="hero-block"></div>
      <div className="hero-block" id="last">
        <div className="hero-text">
          <h1>FreshFace.</h1>
          <p style={{ fontSize: "1.5em", fontVariant: "all-small-caps" }}>
            Your skincare haven
          </p>
          <Link to="/quiz">
            <button>take skin quiz</button>
          </Link>
        </div>
      </div>
      {children}
    </Section>
  );
};

export default Hero;
