import React from "react";
import Section from "../Section";
import facial from "../../resources/facial-treatment.svg";
import drops from "../../resources/drops.svg";
import soap from "../../resources/soap.svg";

const About = ({ children, id }) => {
  return (
    <Section id={id}>
      <div className="about-block">
        <img src={drops} width="180px" />
        <h2>Luxury</h2>
        <p>Offering luxury experiences at an affordable price.</p>
      </div>
      <div className="about-block">
        <img src={facial} width="180px" />
        <h2>Service</h2>
        <p>Offering customized facials, peels & more!</p>
      </div>
      <div className="about-block">
        <img src={soap} width="180px" />
        <h2>Trusted</h2>
        <p>Highly rated and well regarded.</p>
      </div>
      {children}
    </Section>
  );
};

export default About;
