import React, { useEffect, useState } from "react";
import "../style/Section.css";
import handleViewport from "react-in-viewport";

const SectionBlock = ({
  children,
  id,
  inViewport,
  forwardedRef,
  opacityProp,
}) => {
  //   const opacity = inViewport ? 1 : 0;

  const defaultStyle = {
    opacity: opacityProp,
    transition: "opacity 1s",
  };

  useEffect(() => {});

  return (
    <section
      style={defaultStyle}
      className="main-section"
      id={id}
      ref={forwardedRef}
    >
      {children}
    </section>
  );
};

const SectionViewPort = handleViewport(
  SectionBlock /** options: {}, config: {} **/
);

const Section = ({ children, id }) => {
  const [opacity, setOpacity] = useState(0);

  return (
    <SectionViewPort
      id={id}
      opacityProp={opacity}
      onEnterViewport={() => setOpacity(1)}
      //   onLeaveViewport={() => console.log("leave")}
    >
      {children}
    </SectionViewPort>
  );
};

export default Section;
