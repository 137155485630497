export const quotes = [
    {
        name: "Amy",
        testimonial: "Lorem ipsum dolor sit amet"
    },
    {
        name: "Bree",
        testimonial: "Wow good job!"
    },
    {
        name: "Jenny",
        testimonial: "Highly recommended"
    },
    {
        name: "Amanda",
        testimonial: "Amazing!!!!!"
    },
    {
        name: "Quickest wax I’ve ever gotten…In & out!",
        testimonial: "Kayla",
    },
    {
        name: "I always have a great experience, I love it here",
        testimonial: "Deonna",
    },
    {
        name: "The Oxygen Facial w/ LED Light Therapy was everything!! My skin was glowing",
        testimonial: "Jaela",
    },
]