import React, { useState } from "react";

const Dropdown = ({
  title = "shop",
  links = {
    "FreshFace.": "http://shop.freshface.io",
    GlymedPlus: "https://glymedplus.com/web/products/ourline/all_products",
  },
}) => {
  const [isActive, setActive] = useState(false);

  const styles = {
    menuFalse: {
      visibility: "hidden",
      maxHeight: "0px",
      border: "black solid 2px",
      position: "absolute",
      backgroundColor: "white",
      padding: "0rem",
      filter: "drop-shadow(0 5px 0.75rem rgba(0,0,0,0.1))",
      overflow: "hidden",
      transition: "0.25s",
    },
    menu: {
      maxHeight: "200px",
      border: "black solid 2px",
      position: "absolute",
      backgroundColor: "white",
      padding: "1rem",
      filter: "drop-shadow(0 5px 0.75rem rgba(0,0,0,0.1))",
      overflow: "hidden",
      transition: "0.25s",
      // transform: " scaleY(1)",
    },
    link: {
      margin: "1rem",
      display: "block",
    },
  };

  const handleClick = () => {
    setActive(!isActive);
  };

  const menuElement = (
    <div style={isActive ? styles.menu : styles.menuFalse}>
      {Object.entries(links).map((link) => (
        <a style={styles.link} href={link[1]}>
          {link[0]}
        </a>
      ))}
    </div>
  );

  return (
    <div>
      <a onClick={handleClick}>{title}</a>
      {menuElement}
    </div>
  );
};

export default Dropdown;
