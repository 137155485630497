import React from "react";
import Hero from "../components/static/Hero";
import About from "../components/static/About";
import Section from "../components/Section";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import logo from "../resources/ff-full-logo.svg";
import calendar from "../resources/calendar.svg";
import { useEffect, useState } from "react";
import "../style/quotes.css";
import { quotes } from "../resources/quotes";
import { BrowserRouter as Router, Switch, Link, Route } from "react-router-dom";

const Home = () => {
  const position = [41.550510, -72.649590];
  const address = "210 S. Main St, Suite 300B, Middletown, CT 06457";

  return (
    <div className="all-columns">
      {/* ----- Hero ----- */}
      <Hero id="hero-section" />
      {/* ----- About ----- */}
      <About id="about-section" />

      {/* ----- Testimonials ----- */}
      <Section id="testimonials-section">
        <div className="testimonial-block">
          <p id="cycle-testimonial"></p>
          <p id="cycle-name"></p>
        </div>
      </Section>
      {/* ----- Appointment ----- */}
      <Section id="appointment-section">
        <div className="location-block">
          <div style={{ maxWidth: "20rem" }}>
            <h1 className="section-header">BOOK AN APPOINTMENT</h1>
            <p>Services are provided by appointment only. Book yours now!</p>
            <a href="https://www.vagaro.com/ffct/">
              <button>book an appointment</button>
            </a>
          </div>
        </div>
        <div className="location-block">
          <img src={calendar} width="400" />
        </div>
      </Section>
      <Section id="locations-section">
        {/* ----- map ----- */}
        <MapContainer
          id="location-map"
          center={position}
          zoom={17}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position}>
            <Popup>FreshFace.</Popup>
          </Marker>
        </MapContainer>
        <div id="location-copy" className="location-block">
          <div style={{ maxWidth: "20rem" }}>
            <h1 className="section-header">COME STOP BY</h1>
            <p>
              <span style={{ fontWeight: "bold" }}>{address}</span>
              <br />
              <br />
              FreshFace. is conveniently located at Main St in Middletown.
              <br />
              <br />
            </p>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default Home;
